import React from "react";
import './BannerNumbers.css'
import CountUp from 'react-countup';


export default function Component() {
  return (
    <div className="BannerNumbers">
        <div className="BannerNumbers__container">
            <h2 className="BannerNumbers__title">Wild Genius Unleashed</h2>

            <div className="BannerNumbers__numbers__container">
                <p className="BannerNumbers__description">Say hello to Salim Aouas, the tech maestro with a knack for turning your wildest dreams into pixel-perfect reality. From project management to final deployment, he's your guy!</p>
            </div>

            <div className="BannerNumbers_stats__container">
            <div className="BannerNumbers_stats__stat">
                <h3 className="BannerNumbers_stats__stat__number">
                    <CountUp end={28} duration={5} />
                </h3>
                <p className="BannerNumbers_stats__stat__description">Happy Clients</p>
            </div>
            <div className="BannerNumbers_stats__stat">
                <h3 className="BannerNumbers_stats__stat__number">
                    <CountUp end={42} duration={5} />
                </h3>
                <p className="BannerNumbers_stats__stat__description">Network Connections</p>
            </div>
            <div className="BannerNumbers_stats__stat">
                <h3 className="BannerNumbers_stats__stat__number">
                    <CountUp end={6} duration={5} />
                </h3>
                <p className="BannerNumbers_stats__stat__description">Years of experience</p>
            </div>
            </div>
        </div>
       
    </div>

  )
}