import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';

import './App.css';
import BannerTitle from './components/BannerTitle/BannerTitle';
import BannerNumbers from './components/BannerNumbers/BannerNumbers';
import Projects from './components/Projects/Projects';
import BurningQuestions from './components/BurningQuestions/BurningQuestions';
import Footer from './components/Footer/Footer';

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-NP99DKVP'
  }
  TagManager.initialize(tagManagerArgs)
  
  const schemaOrgJSONLD = 
    {
      "@context": "https://schema.org",
      "@type": "Person",
      "name": "Salim Aouas",
      "url": "https://salimaouas.dev",
      "email": "contact@salimaouas.dev",
      "nationality": "French",
      "image": "https://salimaouas.dev/salim-42.jpeg",
      "sameAs": [
        "https://www.linkedin.com/in/salim-aouas-19a8081aa/"
      ],
      "jobTitle": "Project Manager, Software Engineer, Trusted Advisor",
      "worksFor": {
        "@type": "Organization",
        "name": "SAP Concur",
        "url": "https://concur.com"
      },
      "alumniOf": {
        "@type": "Organization",
        "name": "42 Network",
        "url": "https://42network.org"
      }
    };

  return (
    <div className="App">
      <Helmet>
        <title>Salim Aouas | Project Manager | Software Engineer | Trusted Advisor</title>
        <meta name="description" content="Salim Aouas is a Project Manager, Software Engineer, and Trusted Advisor. He is a coding superhero who turns your wildest dreams into pixel-perfect reality." />
        <meta name="keywords" content="Salim Aouas, Project Manager, Software Engineer, Trusted Advisor, Senior Software Engineer, Fullstack Developer, React.js Developer, Node.js Developer, Web Development, UI/UX Design, API Development, GraphQL Expert, Cloud Services, AWS Specialist, Google Cloud Platform, DevOps Engineer, Agile Methodology, JavaScript Professional, TypeScript Developer, SQL Expert, Database Management, System Architecture, Software Testing, Unit Testing, Integration Testing, CI/CD Pipeline, Version Control - GitLab, Technical Project Management, Problem Solving in IT, Software Development Lifecycle, IT Infrastructure Management, Microservices Architecture, Code Refactoring, Team Leadership in Technology" />
        <meta name="author" content="Salim Aouas" />
        <meta name="robots" content="index, follow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
      </Helmet>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&family=Goblin+One&display=swap" rel="stylesheet" />
      </head>
      <main>
        <BannerTitle />
        <BannerNumbers />
        <Projects />
        <BurningQuestions />
        <Footer />
      </main>
    </div>
  );
}

export default App;
