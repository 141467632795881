import React from "react";
import { MovingComponent } from 'react-moving-text'
import './BannerTitle.css'
import cvPDF from '../../Resume Salim Aouas.pdf';

export default function Component() {
  return (
    <div className="BannerTitle">
      <div className="BannerTitle__container">
        <div className="BannerTitle__title">
          <MovingComponent
            type="typewriter"
            dataText={[
              'AWESOME',
              'Project Manager',
              'Software Engineer',
              'Trusted Advisor',
            ]} />
        </div>
  
      </div>
      <div className="BannerTitle__buttons__container">
          <button className="BannerTitle__buttons__button1" onClick={() => window.open('https://calendly.com/salimaouas/30min', "_blank")}>Book a call</button>
          <a href={cvPDF} download="Resume Salim Aouas.pdf">
            <button className="BannerTitle__buttons__button2">Resume</button>
          </a>
        </div>
    </div>
  )
}