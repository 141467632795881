import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="Footer__container">
                <div className="Footer__title__container">
                    <h2 className="Footer__title">Intrigued yet ?</h2>
                </div>
                <div className="Footer__description">
                    <div className="Footer__description__container">
                        <p>Now that you’ve had a peek into Salim's world, it's time to take the next step. Get in touch. You won't regret it. Seriously, who wouldn't want to work with a coding superhero?</p>
                    </div>
                    <div className="Footer_button_container">
                        <button onClick={() => window.open('https://calendly.com/salimaouas/30min', "_blank")}>Contact Salim</button>
                    </div>
                </div>
            </div>
            <div className="Footer_decoration_left" />
            <div className="Footer_decoration_right" />

            <div className="Footer__container_copyrights">
                <div className="Footer__container_socials">
                    <div className="Footer__socials">
                        <div onClick={() => window.open('https://fr.linkedin.com/in/salim-aouas-19a8081aa', "_blank")} className='Footer_socials_linkedin_container'>
                        <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 64 64" width="64px" height="64px"><path d="M48,8H16c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8V16C56,11.582,52.418,8,48,8z M24,47h-5V27h5 V47z M24.029,23.009C23.382,23.669,22.538,24,21.5,24c-1.026,0-1.865-0.341-2.519-1.023S18,21.458,18,20.468 c0-1.02,0.327-1.852,0.981-2.498C19.635,17.323,20.474,17,21.5,17c1.038,0,1.882,0.323,2.529,0.969 C24.676,18.615,25,19.448,25,20.468C25,21.502,24.676,22.349,24.029,23.009z M47,47h-5V35.887C42,32.788,40.214,31,38,31 c-1.067,0-2.274,0.648-2.965,1.469S34,34.331,34,35.594V47h-5V27h5v3.164h0.078c1.472-2.435,3.613-3.644,6.426-3.652 C44.5,26.5,47,29.5,47,34.754V47z"/></svg>
                        </div>
       
                            </div>
                </div>
                <div className="Footer__container_credits">
                    <div className="Footer__credits">
                        <p>All Rights Reserved by Salim Aouas &copy;</p>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Footer;