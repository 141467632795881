import React from "react";
import './Projects.css'

export default function Component() {
    return (
        <div className="Projects">
            <div className="Projects__container">
                <div className="Projects__title__container">
                    <h2 className="Projects__title">Art of Code</h2>
                </div>
                <div className="Projects__description__container_base">
                    <div className="Projects__description__container">
                        <div className="projects__project">
                            <img src="sap_concur.png" alt="SAP Concur Software" />
                            <div className="project-description">
                                <p className="project-description-text">
                                    At SAP Concur, I resolved customer issues in a 70M user environment and refactored the codebase with modern design patterns and TypeScript. I developed advanced UIs using React and implemented effective caching strategies, enhancing user experience. My role included thorough testing, backend development with NodeJS/GraphQL, and managing production releases. I fostered a collaborative culture through code reviews and pair programming, ensuring system reliability and performance.
                                </p>
                                <a href="https://concur.com" target="_blank" rel="noopener noreferrer" className="project-link">Visit website</a>
                            </div>
                        </div>
                        <div className="projects__project">
                            <img src="42school.png" alt="42 Network" />
                            <div className="project-description">
                                <p className="project-description-text">
                                    At 42 Network in Spain, I spearheaded the technical setup for launching four educational campuses, managed over 1600 iMacs, and enhanced IT infrastructure. I developed and maintained scalable, secure backend APIs and improved code quality using TypeScript. Leading and mentoring a team of 8, I focused on continuous IT system enhancements, combining extensive testing with effective network administration. My role was pivotal in blending hands-on technical work with strategic educational leadership.
                                </p>
                                <a href="https://42network.org" target="_blank" rel="noopener noreferrer" className="project-link">Visit website</a>
                            </div>
                        </div>
                        <div className="projects__project">
                            <img src="trusk.png" alt="PFG" />
                            <div className="project-description">
                                <p className="project-description-text">
                                    In my role at TRUSK, Paris, I led a pivotal migration to Google Cloud, developed and tested integral projects, and automated essential operations. My creation of a logistics optimization algorithm and SQL dashboards significantly boosted data analytics and operational efficiency. Through collaboration, I substantially contributed to the advancement of the company's technological methodologies, demonstrating a strong blend of innovation and leadership.
                                </p>
                                <a href="https://www.trusk.com/" target="_blank" rel="noopener noreferrer" className="project-link">Visit website</a>
                            </div>
                        </div>
                        <div className="projects__project">
                            <img src="pfg.png" alt="PFG" />
                            <div className="project-description">
                                <p className="project-description-text">
                                At PFG, I developed a dynamic application with a CMF for content management, introducing automatic SEO and an SSR warming mechanism to boost performance. I designed a unique Location API and created a bespoke UI kit, aligning with branding and UX needs. My contributions included a custom form engine, an Airbnb-like directory, and an integrated product store. Leading frontend architecture, I ensured scalable, high-performing designs, conducted code reviews, and guided junior developers.
                                </p>
                                <a href="https://pfg.fr" target="_blank" rel="noopener noreferrer" className="project-link">Visit website</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}