import './BurningQuestions.css'

const Component = () => {
    return (
        <div className="BurningQuestions">
            <div className="BurningQuestions__container">
                <div className="BurningQuestions__title__container">
                    <h2 className="BurningQuestions__title">Burning Questions</h2>
                </div>
                <div className="BurningQuestions__description__container_base">
                    <div className="BurningQuestions__description__container">
                        <div className="BurningQuestions__group_question">
                            <div className="BurningQuestions__question">
                                What unique skills do you bring to a project?
                            </div>
                            <div className="BurningQuestions_answer">
                                With extensive experience in Fullstack Development, UI/UX Design, and Technical Leadership, I bring a unique blend of innovative problem-solving, efficient process optimization, and high-quality code development. My expertise in technologies like React, Node.js, TypeScript, and GraphQL ensures robust and scalable solutions tailored to specific project needs.
                            </div>

                        </div>

                        
                        <div className="BurningQuestions__group_question">
                            <div className="BurningQuestions__question">
                                How do you ensure project success in a dynamic environment?
                            </div>

                            <div className="BurningQuestions_answer">
                                My approach combines agile methodologies with a focus on continuous improvement and adaptability. I prioritize understanding client needs and user experiences, ensuring that every project is aligned with its intended goals. Regular testing, performance monitoring, and collaborative team management are  key practices that I use to guarantee success even in the most dynamic environments.
                            </div>
                        </div>
                    </div>


                    <div className="BurningQuestions__description__container">
                        <div className="BurningQuestions__group_question">
                            <div className="BurningQuestions__question">
                                Can you describe a challenging project you’ve worked on and how you overcame those challenges?
                            </div>

                            <div className="BurningQuestions_answer">
                                At SAP Concur, I tackled customer issues in a platform with 70M users, requiring significant codebase refactoring. By implementing modern design patterns and TypeScript, I enhanced maintainability without disrupting service. My solution-oriented mindset and technical adaptability enabled me to transform challenges into opportunities for improvement and innovation.
                            </div>
                        </div>

                        <div className="BurningQuestions__group_question">
                            <div className="BurningQuestions__question">
                                What is your approach to team collaboration and leadership?
                            </div>

                            <div className="BurningQuestions_answer">
                                My leadership style is grounded in fostering a culture of collaboration, open communication, and mutual respect. I believe in leading by example, mentoring junior team members, and encouraging a continuous learning environment. I emphasize the importance of regular code reviews and pair programming to not only maintain high standards but also to build a cohesive and skilled team capable of tackling complex projects.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Component;
